import React, { useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import styled from "styled-components"
import photo1 from "../assets/images/magdalenagold.png"
import photo4 from "../assets/images/article3.webp"
import hygienist from "../assets/images/hygienist.webp"
import { Parallax } from "react-parallax"
import { FiArrowRight } from "react-icons/fi"
import articlePDF from "../assets/images/Higiena jamy ustnej.pdf"
import { Swiper, SwiperSlide } from "swiper/react"
import Seo from "../components/Seo"
import certificates from "../components/Certificates"
import AOS from "aos"
import "aos/dist/aos.css"

import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/pagination"
import "swiper/css/navigation"
import { FreeMode, Pagination, Keyboard, Lazy, Navigation } from "swiper"

import "../assets/css/main.css"

const AboutWrapper = styled.section`
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
`

const ArticleText = styled.article`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 4rem 2rem;

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`

const ArticleTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  padding: 1rem 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;

  @media (max-width: 768px) {
    width: 100vw;
  }
`

const StyledH1 = styled.h1`
  text-align: center;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  margin: 0;
  text-transform: uppercase;
  color: white;
  text-shadow: 4px 4px 2px rgba(0, 0, 0, 1);

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`

const StyledH2 = styled.h2`
  text-align: center;
  font-family: "Parisienne";
  font-size: 5rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  color: white;
  text-shadow: 4px 4px 2px rgba(0, 0, 0, 1);

  @media (max-width: 768px) {
    font-size: 4rem;
  }
`

const StyledParagraph = styled.p`
  text-align: center;
  letter-spacing: 1px;
  padding: 1rem 1rem;
  width: 60%;
  font-weight: 600;
  color: white;
  text-shadow: 4px 4px 2px rgba(0, 0, 0, 1);

  @media (max-width: 768px) {
    width: 90%;
  }
`

const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  max-width: 100%;
  max-height: 40vh;
  border: 10px solid #cbb26a;
  overflow: hidden;

  @media (max-width: 480px) {
    border: 2px solid #cbb26a;
  }
  @media (orientation: landscape) and (max-width: 915px) {
    border: 10px solid #cbb26a;
    max-height: 50vh;
  }
`
const StyledContactH3 = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2rem;
  width: 90%;
  transform: translateX(10%);
  &span {
    color: #cbb26a;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
  }
  @media (max-width: 480px) {
    font-size: 1rem;
    padding: 0.5rem 0.5rem;
  }
  @media (max-width: 320px) {
    font-size: 0.6rem;
  }
  @media (orientation: landscape) and (max-width: 915px) {
    font-size: 1.5rem;
    margin-top: 2.5rem;
  }
`
const StyledContactPhoto = styled.img`
  align-self: flex-end;
  justify-self: flex-end;
  max-height: 40vh;

  @media (max-width: 480px) {
    max-height: 30vh;
  }
`

const StyledLink = styled(Link)`
  font-size: 5rem;
  padding: 1rem 1rem;
  color: #cbb26a;
  &:visited,
  &:active {
    color: #cbb26a;
    text-decoration: none;
  }
`

const ExtLink = styled.a`
  font-size: 5rem;
  padding: 1rem 1rem;
  color: #cbb26a;
  &:visited,
  &:active {
    color: #cbb26a;
    text-decoration: none;
  }
`

const MagazineArticle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 4px 4px 2px rgba(0, 0, 0, 1);
`
const MagazineTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin: 2rem 0rem;
  padding: 1rem 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;

  @media (max-width: 768px) {
    width: 95%;
  }
`

const StyledSectionTitle = styled.h2`
  font-size: 2.5rem;
  padding: 1.5rem 0rem;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`
const StyledTitle = styled.h2`
  font-size: 2rem;
  padding: 0.5rem 0rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const StyledSubtitle = styled.h3`
  font-size: 1.5rem;
  padding: 0.5rem 0rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    text-align: center;
  }
`
const StyledSpan = styled.span`
  font-size: 1.2rem;
  color: #cbb26a;
  padding: 1rem 0rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    text-align: center;
  }
`

const StyledText = styled.p`
  text-align: center;
  margin-bottom: 2rem;
  width: 80%;
  font-size: 1.2rem;

  @media (max-width: 768px) {
    text-align: center;
    width: 100%;
  }
`

const StyledListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`

const StyledList = styled.ul`
  width: 50%;
  font-size: 1.2rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    text-align: left;
    font-size: 1.2rem;
  }
`

const StyledListElement = styled.li`
  padding: 1rem 1rem;
  list-style: none;

  @media (max-width: 768px) {
    padding: 1rem 0.5rem;
  }
`

const StyledDocs = styled.div`
  text-align: center;
  min-height: 100%;
  width: 100%;
  margin: 1rem 0rem;
`
const SwiperCertWrapper = styled.div`
  height: 100%;
  max-width: 100%;
`

const CertificatesWrapper = styled.div`
  min-height: 100%;
  max-height: 100%;
  width: 100%;
`
const CertificateImage = styled.img`
  height: auto;
  width: 90%;
`

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    })
  }, [])

  return (
    <Layout>
      <Seo title="O mnie" />
      <AboutWrapper data-aos="fade-up">
        <Parallax
          blur={0}
          bgImage={hygienist}
          bgImageAlt="Photo by Yusuf Belek on Unsplash"
          bgStyle={{ paddingBottom: "30px" }}
          bgImageStyle={{ top: "-20%" }}
          strength={300}
        >
          <ArticleText>
            <ArticleTextWrapper>
              <StyledH1>O mnie</StyledH1>
              <StyledH2>Magdalena Romatowska</StyledH2>
              <StyledParagraph>
                Higienistka, asystentka stomatologiczna – Absolwentka Szkoły
                Medycznej i Wyższej Szkoły Administracyjno-Społecznej w
                Warszawie. Ze stomatologią związana od 10 lat, przemierzyła
                ścieżkę od asysty stomatologicznej do kierownika zespołu
                okołomedycznego i higienistki stomatologicznej.
              </StyledParagraph>
              <StyledParagraph>
                Wykonując zabiegi higienizacyjne kieruje się indywidualnym
                podejściem, stawia na edukację pacjentów, omówienie stanu
                higieny jamy ustnej oraz nakreślenie ścieżki rutyny
                higienizacyjnej dostosowanej do sytuacji oraz motywacji
                pacjenta.
              </StyledParagraph>
              <StyledParagraph>
                Zwolenniczka metody małych kroków, zapewniającej wprowadzenie i
                wytrenowanie u pacjentów konsekwencji oraz precyzji, które w
                zakresie higieny mają ogromne znaczenie. W pełni realizuje się w
                swoim zawodzie, stale podnosi kwalifikacje poprzez uczestnictwo
                w szkoleniach. Szczególnie zainteresowana kwestiami związanymi z
                psychologią i metodami motywacji pacjentów.
              </StyledParagraph>
            </ArticleTextWrapper>
          </ArticleText>
        </Parallax>
        <ContactWrapper data-aos="fade-right">
          <StyledContactH3>
            Zapraszam na wizyty higienizacyjne,
            <span>znajdziesz mnie tutaj</span>
            <StyledLink to="/contact">
              <FiArrowRight />
            </StyledLink>
          </StyledContactH3>
          <StyledContactPhoto
            src={photo1}
            alt="dental-hygienist"
            title="Higienistka Magdalena Romatowska"
          ></StyledContactPhoto>
        </ContactWrapper>
        <StyledDocs>
          <StyledTitle>Certyfikaty</StyledTitle>
          <SwiperCertWrapper data-aos="fade-left">
            <Swiper
              style={{ padding: "2rem 0rem" }}
              slidesPerView={3}
              spaceBetween={30}
              freeMode={true}
              pagination={{
                clickable: true,
                type: "bullets",
                dynamicBullets: true,
              }}
              modules={[FreeMode, Pagination, Keyboard, Lazy, Navigation]}
              className="mySwiper"
              keyboard={{ enabled: true, onlyInViewport: false }}
              lazy={false}
              navigation={true}
              autoHeight={false}
              preloadImages={true}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
            >
              {certificates.map((imgSrc, index) => (
                <SwiperSlide className="swiper-image">
                  <CertificatesWrapper>
                    <CertificateImage
                      src={imgSrc.src}
                      key={index}
                      alt="Certyfikaty Higienistka Magdalena Romatowska"
                      className="swiper-lazy"
                      title="Certyfikaty Higienistka Magdalena Romatowska"
                    />
                  </CertificatesWrapper>
                </SwiperSlide>
              ))}
            </Swiper>
          </SwiperCertWrapper>
        </StyledDocs>
        <Parallax
          bgImage={photo4}
          bgImageAlt="Article from 'Niezbędnik stomatologiczny'"
          strength={350}
          alt="Article from 'Niezbędnik stomatologiczny'"
          title="Artykuł Higienistka Magdalena Romatowska"
        >
          <MagazineArticle>
            <MagazineTextWrapper>
              <StyledSectionTitle>Publikacje</StyledSectionTitle>
              <StyledTitle>Higiena jamy ustnej</StyledTitle>
              <StyledSubtitle>
                w trakcie leczenia ortodontycznego aparatem stałym
              </StyledSubtitle>
              <StyledSpan>
                'Niezbędnik Stomatologiczny' nr 2 <br />| Listopad 2019 |
              </StyledSpan>
              <StyledText>
                Noszenie aparatu stałego sprzyja gromadzeniu się resztek
                pokarmów i płytki bakteryjnej na zębach oraz elementach aparatu.
                Powstałe w jamie ustnej środowisko stanowi idealne podłoże do
                rozwoju próchnicy, chorób dziąseł czy przebarwień, dlatego tak
                ważne jest, aby od momentu założenia aparatu ortodontycznego
                przykładać jeszcze większą uwagę do codziennej higieny jamy
                ustnej.
              </StyledText>
              <StyledText>
                Przedstawiam kilka wskazówek dotyczących higieny jamy ustnej, o
                których warto pamiętać decydując się na leczenie ortodontyczne:
              </StyledText>
              <StyledListWrapper>
                <StyledList>
                  <StyledListElement>
                    <strong>1.</strong> Należy myć zęby po każdym posiłku (gdy
                    nie ma takiej możliwości, pacjent powinien obficie
                    przepłukać usta wodą).
                  </StyledListElement>
                  <StyledListElement>
                    <strong>2.</strong> Warto dobrze zastanowić się nad doborem
                    odpowiedniej szczoteczki do zębów - lekarz ortodonta lub
                    higienistka stomatologiczna z pewnością zasugerują najlepsze
                    rozwiązanie dostosowane do potrzeb pacjenta oraz możliwości
                    finansowych (osobiście najczęściej polecam szczoteczki
                    elektryczne, co ważne, posiadające funkcję kontroli siły
                    nacisku).
                  </StyledListElement>
                  <StyledListElement>
                    <strong>3.</strong> Wybierając pastę do zębów, polecam
                    zdecydować się na tę zawierającą fluor i odrzucić te, które
                    posiadają właściwości wybielające.
                  </StyledListElement>
                  <StyledListElement>
                    <strong>4.</strong> Kompletując podręczną kosmetyczkę,
                    koniecznie należy zaopatrzyć się w szczoteczki międzyzębowe
                    i szczoteczki jednopęczkowe, które są niezastąpione w
                    czyszczeniu powierzchni przy zamkach, drucikach czy
                    pierścieniach.
                  </StyledListElement>
                  <StyledListElement>
                    <strong>5.</strong> Przynajmniej raz dziennie, przed snem,
                    obowiązkowo należy dokładnie wynitkować zęby. Warto
                    zdecydować się na specjalną nić dentystyczną dedykowaną
                    osobom noszącym aparaty stałe. Nić ortodontyczna różni się
                    od zwykłej tym, że składa się z 3 elementów: usztywnionego
                    końca, który pomaga precyzyjnie wprowadzać nitkę, części
                    puszystej, która bardzo dobrze oczyszcza miejsca wokół
                    zamków oraz części zwykłej nitki do oczyszczania powierzchni
                    stycznych zębów.
                  </StyledListElement>
                </StyledList>
                <StyledList>
                  <StyledListElement>
                    <strong>6.</strong> Można zaopatrzyć się w płukanki do jamy
                    ustnej, ponieważ działają przeciwbakteryjnie i odświeżająco
                    - polecam stosowanie, ale tylko tych bez zawartości
                    alkoholu.
                  </StyledListElement>
                  <StyledListElement>
                    <strong>7.</strong> Warto rozważyć nabycie irygatora - jest
                    to świetne urządzenie, które ułatwia higienę, ale - co ważne
                    - nie zastępuje szczoteczki do zębów oraz nitki
                    stomatologicznej. Po wyszczotkowaniu i wynitkowaniu zębów
                    można zastosować irygator do osiągnięcia pełnej czystości.
                    Irygator ułatwia usunięcie resztek pokarmów z trudno
                    dostępnych i ukrytych miejsc, szczególnie gdy pacjent ma
                    trudności w precyzyjnym sterowaniu nicią dentystyczną w
                    obrębie jamy ustnej. Irygator to urządzenie, którego główna
                    funkcja polega na czyszczeniu przestrzeni międzyzębowych
                    strumieniem pod ciśnieniem. Polecany jest wszystkim, nie
                    tylko osobom noszącym aparaty ortodontyczne.
                  </StyledListElement>
                  <StyledListElement>
                    <strong>8.</strong> Niezwykle istotna jest także
                    zbilansowana dieta i rozsądne podejście do wyboru
                    konkretnych produktów spożywczych. Najważniejsze, aby unikać
                    twardych pokarmów (np. orzechów), napojów gazowanych (np .
                    typu Cola) czy lepkich i miałkich przekąsek, które trudno
                    jest usunąć (np. chipsów). Polecam również zrezygnować z gum
                    do żucia.
                  </StyledListElement>
                </StyledList>
              </StyledListWrapper>

              <StyledText>
                Mam świadomość tego, że lista wskazówek jest nieco długa, ale
                uwierzcie, drodzy Pacjenci, już po kilku dniach nowe czynności
                wejdą Wam w nawyk i staną się standardowym elementem
                stomatologicznej rutyny. Warto się do nich sprawnie
                przyzwyczaić, ponieważ leczenie ortodontyczne zazwyczaj jest
                długotrwałe, a sam proces higieny i pielęgnacji polecam
                traktować jako element drogi do pięknego uśmiechu niż niechciany
                obowiązek. Dodatkowym walorem jest fakt, że wytrenowane
                przyzwyczajenia staną się tak silne, że nawet po zdjęciu aparatu
                zachowana zostanie wyćwiczona rutyna, czyli perspektywa pięknego
                i zdrowego uśmiechu na długo.
              </StyledText>

              <ExtLink
                href={articlePDF}
                download="My_File.pdf"
                style={{ fontSize: "1rem", backgroundColor: "transparent" }}
                title="Higienistka Magdalena Romatowska | Artykuł"
              >
                Pobierz artykuł w PDF
              </ExtLink>
            </MagazineTextWrapper>
          </MagazineArticle>
        </Parallax>
      </AboutWrapper>
    </Layout>
  )
}

export default About
