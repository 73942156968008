import cert1 from "../assets/certificates/1.webp"
import cert2 from "../assets/certificates/2.webp"
import cert3 from "../assets/certificates/3.webp"
import cert4 from "../assets/certificates/4.webp"
import cert5 from "../assets/certificates/5.webp"
import cert6 from "../assets/certificates/6.webp"
import cert7 from "../assets/certificates/7.webp"
import cert8 from "../assets/certificates/8.webp"
import cert9 from "../assets/certificates/9.webp"
import cert10 from "../assets/certificates/10.webp"
import cert11 from "../assets/certificates/11.webp"
import cert12 from "../assets/certificates/12.webp"
import cert13 from "../assets/certificates/13.webp"

const certificates = [
  { src: cert1, text: "Certyfikat za ...." },
  { src: cert2, text: "Certyfikat za ...." },
  { src: cert3, text: "Certyfikat za ...." },
  { src: cert4, text: "Certyfikat za ...." },
  { src: cert5, text: "Certyfikat za ...." },
  { src: cert6, text: "Certyfikat za ...." },
  { src: cert7, text: "Certyfikat za ...." },
  { src: cert8, text: "Certyfikat za ...." },
  { src: cert9, text: "Certyfikat za ...." },
  { src: cert10, text: "Certyfikat za ...." },
  { src: cert11, text: "Certyfikat za ...." },
  { src: cert12, text: "Certyfikat za ...." },
  { src: cert13, text: "Certyfikat za ...." },
]

export default certificates
